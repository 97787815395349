import type {ResizePanelProps} from '@Panels/resize-panel/resize-panel';
import {MODALS_IDS} from '@Components/modal-container';
import {useDispatch} from 'react-redux';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import React from 'react';
import type {CopyAndResizeData, ResizeData} from '@Panels/resize-panel/resize-panel.types';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'resize-modal-panel';

export type ResizeModalProps = ResizePanelProps;

export default function ResizeModal() {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.RESIZE_MODAL];
  });
  const dispatch = useDispatch();

  const height = '855px';
  const width = '425px';

  return (
    <Modal isBottomSheetOnMobile mobileHeight="855px" modalId={MODALS_IDS.RESIZE_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="resize-panel"
        panelProps={{
          ...modalData.panelProps,
          onResizeCallback: (resizeCTA: ResizeData) => {
            modalData.panelProps.onResizeCallback(resizeCTA);
            dispatch(closeModal(MODALS_IDS.RESIZE_MODAL));
          },
          onCopyAndResize: (copyAndResizeCTA: CopyAndResizeData) => {
            modalData.panelProps.onCopyAndResize(copyAndResizeCTA);
            dispatch(closeModal(MODALS_IDS.RESIZE_MODAL));
          },
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openResizeModal = (props: ResizeModalProps) => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.RESIZE_MODAL,
      panelProps: props,
    })
  );
};
