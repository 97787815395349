import type {ReactElement} from 'react';
import React, {useEffect} from 'react';
import {Modal} from '@Components/modal';
import type {UserMenuPanelProps} from '@Panels/user-menu-panel/user-menu-panel';
import {closeModal, openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MenuItemData, USER_MENU_LIST_ID} from '@Panels/user-menu-panel/user-menu-panel.types';
import {setUserItemsLoaded} from '@Panels/user-menu-panel/user-menu-panel-reducer';
import {isUserLoggedIn} from '@Libraries/user.library';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import {useDispatch} from 'react-redux';
import Emitter, {EVENTS} from '../../../services/event';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'user-menu-modal-panel';

export type UserMenuModalProps = UserMenuPanelProps;

export default function UserMenuModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.USER_MENU_MODAL];
  });
  const dispatch = useDispatch();
  const menuItems: Array<string> = useAppSelector((state) => {
    return state.list.listData.listHashMap[USER_MENU_LIST_ID]?.listItemIds ?? [];
  });
  const isModalOpen = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.USER_MENU_MODAL]?.isOpen ?? '';
  });
  const height = '750px';
  const width = '1260px';

  useEffect(() => {
    if (!isUserLoggedIn()) {
      Emitter.on(EVENTS.USER_LOGGED_IN, onUserLoggedIn);
    }
    return () => {
      Emitter.off(EVENTS.USER_LOGGED_IN, onUserLoggedIn);
    };
  }, [isModalOpen]);

  const onUserLoggedIn = async (): Promise<void> => {
    if (menuItems.length && !isModalOpen) {
      await window.PMW.writeLocal('menuitem/linkmenuitems', {
        menuItemIds: menuItems,
      });
      dispatch(setUserItemsLoaded(false));
    }
  };

  return (
    <Modal modalId={MODALS_IDS.USER_MENU_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="user-menu-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openUserMenuModal = (props: UserMenuModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.USER_MENU_MODAL,
      panelProps: props,
    })
  );
};
