import {unhidePanel} from '@Components/panel/panel-reducer';
import {noop} from '@Utils/general.util';

export const triggerResumablePanelOpening = (panelID: string, modalOpeningInitiator: () => void = noop): void => {
  const isPanelHidden = !!window.PMW.redux.store.getState().panels.panelHashmap[panelID]?.isHidden;
  const shouldPanelHideOnClose = !!window.PMW.redux.store.getState().panels.panelHashmap[panelID]?.hideOnClose;

  if (shouldPanelHideOnClose && isPanelHidden) {
    window.PMW.redux.store.dispatch(unhidePanel(panelID));
  } else {
    modalOpeningInitiator();
  }
};
